<template>
  <div
    v-editable="blok"
    class="container px-0 pb-[--blockSpacing] lg:px-5 lg:pb-0"
  >
    <div v-if="isGrid" class="hidden grid-cols-4 gap-2.5 lg:grid lg:gap-9">
      <CardCallToAction
        v-for="card in callToActionCards"
        :key="card._uid"
        :card="card"
      />
    </div>

    <Swiper
      :slides-per-view="1.45"
      :slides-offset-before="20"
      :slides-offset-after="20"
      :breakpoints="swiperBreakpoints"
      :class="{
        'lg:!hidden': isGrid,
      }"
      space-between="10px"
      touch-events-target="container"
      class="!overflow-visible px-5"
    >
      <SwiperSlide v-for="card in callToActionCards" :key="card._uid">
        <CardCallToAction :card="card" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import type { SwiperOptions } from 'swiper/types'
import screens from '#tailwind-config/theme/screens'
import type { BlockCallToActionListStoryblok } from '@/types/storyblok'
import { stripPx } from '@/utils/common-utils'
import type { CardCallToAction } from '@/components/card/CardCallToAction.vue'
import { formatLink } from '@/utils/link-utils'

const props = defineProps<{ blok: BlockCallToActionListStoryblok }>()
const isGrid = computed(() => props.blok.cards.length <= 4)
const swiperBreakpoints = ref<SwiperOptions['breakpoints']>({
  [stripPx(screens.sm)]: {
    slidesPerView: 2.45,
    spaceBetween: 10,
  },
  [stripPx(screens.lg)]: {
    slidesPerView: 4,
    spaceBetween: 36,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
})
const callToActionCards = computed(() => {
  return props.blok.cards.map((card) => {
    return {
      _uid: card._uid,
      title: card.title,
      description: card.description,
      image: card.image,
      link: formatLink(card.link.cached_url),
    } as CardCallToAction
  })
})
</script>
